@import "~antd/dist/antd.less";

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #7a7a7a !important;
  opacity: 1; /* Firefox */
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.site-layout-content {
  min-height: 100vh;
}

.center {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}


.logo {
    background-image: url('logo.png');
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
    width: 64px;
    height: 64px;
    display: inline-block;
}

.site-layout-content {
  min-height: calc(100vh - 64px);
}

.ant-layout-header input {
  color: white;
}

.rotated-text {
}

.ant-table-tbody > tr > .ant-table-cell.nopadding {
  padding: 0 !important;
}

@media only screen and (max-width: 480px) {
  .ant-layout-sider.ant-layout-sider-collapsed {
    min-width: 0px !important;
    max-width: 0px !important;
    overflow: hidden;
  }
}

@media print {
  * {
    background-color: white !important;
  }
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 5mm 5mm 10mm 5mm;
    @bottom-left {
      content: counter(page) "/" counter(pages);
    }
  }
  table {
    border: none !important;
  }
  @bottom-left {
    content: counter(page) "/" counter(pages);
  }
  .drag-visible, .noprint, .ant-table-selection-column {
    display: none !important;
  }
  .fullWidth {
    width: 100%;
  }
  //body {
  //  margin: 25mm 25mm 25mm 25mm;
//
  //}
  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 4px 4px;
  }

  .ant-table.ant-table-bordered td {
    border-bottom: #c8c8c8 solid 1px;
  }
  .pagebreak {
    page-break-before: always;
    page-break-inside: avoid;
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 2px !important;
  }

  .ant-table.ant-table-small .ant-table-tbody {
    font-size: 8pt;
  }

  table { page-break-inside:auto }
  tr    { page-break-inside:avoid; page-break-after:auto; }
  td    { page-break-inside:avoid; page-break-after:auto }
  thead { display:table-header-group }
  tfoot { display:table-footer-group }

  .lh-10 { line-height: 1; }
  .lh-11 { line-height: 1.1; }
  .lh-12 { line-height: 1.2; }
  .lh-13 { line-height: 1.3; }
  .lh-14 { line-height: 1.4; }
  .lh-15 { line-height: 1.5; }
  .lh-16 { line-height: 1.6; }
  .lh-17 { line-height: 1.7; }
  .lh-18 { line-height: 1.8; }
  .lh-19 { line-height: 1.9; }
  .lh-20 { line-height: 2; }
}

.clear {
  clear: both;
}

.flexed {
  display: flex;
  flex-direction: column;
}

tr[data-row-key="0"]:not(.moved), tr[data-row-key^="a0-"]:not(.moved) {
  background-color: rgba(255, 0, 0, 0.29);
}

.dirty {
  background-color: rgba(255, 140, 0, 0.844);
}

.moved {
  background-color: rgba(145, 255, 0, 0.844);
}

.ant-table-selection-column > * {
  display: none;
}

.ant-form-inline > .ant-form-item {
  display: inline-flex;

}
.ant-form-inline > .ant-form-item .ant-form-item-explain.ant-form-item-explain-connected {
  position: absolute;
    width: max-content;
}

.soft {
  background-color: rgba(0,0,0,.1);
}

.empty {
  display: none !important;
}
@media not print {
  .delivered > td:first-of-type {
    color: white;
    background-color: green;
    font-weight: 900;
    text-decoration: underline;
  }
}
@primary-color: #1DA57A;@disabled-color: #a3a3a3;@select-item-active-bg: #a3a3a3;